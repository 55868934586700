import { useLocalization } from '../../../common/providers/LocaleProvider';
import { trackCloseButtonClick } from '../tracking';
export var useSideNavTracking = function (_a) {
    var navigationParentName = _a.navigationParentName, onClickCloseButton = _a.onClickCloseButton, onBackButtonClick = _a.onBackButtonClick, shopName = _a.shopName;
    var _b = useLocalization(), r = _b.r, t = _b.t;
    return {
        backButtonProps: {
            'aria-label': r(t('h24_back_to_category'), { name: navigationParentName }),
            'data-testid': 'sidenav-back-button',
            onClick: onBackButtonClick,
        },
        closeButtonProps: {
            'aria-label': t('h24_close_menu'),
            'data-testid': 'sidenav-close-button',
            onClick: function () {
                onClickCloseButton();
                trackCloseButtonClick(t('h24_close_menu'), shopName);
            },
        },
    };
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { borderBottom, space } from 'styled-system';
import { HeadlineH6 } from '../../common/components/Typography';
import { useLocalization } from '../../common/providers/LocaleProvider';
import { listStyle } from '../../common/theme/system-utilities';
import { useShowPromoImage } from './hooks/useNavigationItems';
import { NavigationListItems } from './NavigationListItems';
export var NAV_TITLE_ID = 'navigation-title';
export var NavigationGroup = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n\n  &:not(:last-of-type) {\n    border-bottom: none;\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n\n  &:not(:last-of-type) {\n    border-bottom: none;\n  }\n"])), listStyle, space, borderBottom);
export var navigationGroupStyles = {
    listStyle: 'none',
    m: 'sp_0',
    p: 'sp_0',
    mb: 'sp_16',
    role: 'menu',
    borderBottom: 'none',
};
var titleStyles = {
    py: 'sp_12',
    pl: 'sp_16',
};
var NavigationList = function (_a) {
    var navigation = _a.navigation, selectedItem = _a.selectedItem, props = __rest(_a, ["navigation", "selectedItem"]);
    var t = useLocalization().t;
    var withPromoImage = useShowPromoImage({
        selectedItem: selectedItem,
        navLevel: props.navLevel,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(HeadlineH6, __assign({ id: NAV_TITLE_ID }, titleStyles), navigation.name ? navigation.name : t('h24_categories')),
        React.createElement(NavigationGroup, __assign({ "data-testid": "navigation-group-".concat(props.navLevel) }, navigationGroupStyles),
            React.createElement(NavigationListItems, __assign({}, props, { withPromoImage: withPromoImage, items: navigation.children, navigation: navigation, groupId: props.navLevel, selectedItem: selectedItem })))));
};
export default NavigationList;
var templateObject_1;

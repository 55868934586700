var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { space } from 'styled-system';
import h24LogoSvg from '../../common/assets/images/home-24-logo.svg';
import { useLocalization } from '../../common/providers/LocaleProvider';
import { objectFit } from '../../common/theme/system-utilities';
import { trackLogoClick } from './tracking';
var HeaderContainer = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
var LogoImage = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), objectFit);
var useContainerLinkStyles = function (shopUrl, logoUrl) {
    var p = useLocalization().p;
    if (shopUrl) {
        return { href: shopUrl };
    }
    return logoUrl ? { as: 'div' } : { href: p.t('h24_home_url') };
};
var SideNavHeaderTitle = function (_a) {
    var isRootLevel = _a.isRootLevel, shopName = _a.shopName, shopUrl = _a.shopUrl, logoUrl = _a.logoUrl;
    var t = useLocalization().t;
    var containerLinkStyles = useContainerLinkStyles(shopUrl, logoUrl);
    if (!isRootLevel) {
        return null;
    }
    return (React.createElement(HeaderContainer, __assign({ "data-testid": "sidenav-heading", p: "sp_16", onClick: function () { return trackLogoClick(shopName); } }, containerLinkStyles),
        React.createElement(LogoImage, { src: logoUrl !== null && logoUrl !== void 0 ? logoUrl : h24LogoSvg, alt: shopName !== null && shopName !== void 0 ? shopName : t('h24_logo_alt_title'), width: "auto", height: 40, objectFit: "contain" })));
};
export default SideNavHeaderTitle;
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from '@emotion/styled';
import { color, fontSize, space, textAlign, themeGet, width, } from 'styled-system';
import { Flex } from '../../common/components/Flex';
import { modifiers } from '../../common/theme/helper';
import { textDecoration } from '../../common/theme/system-utilities';
var BaseNavigationLink = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  &:hover {\n    background-color: ", ";\n  }\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  &:hover {\n    background-color: ", ";\n  }\n  &:active {\n    background-color: ", ";\n  }\n"])), color, fontSize, modifiers, space, textAlign, textDecoration, width, themeGet('colors.sys.neutral.background.hover'), themeGet('colors.sys.neutral.background.active'));
BaseNavigationLink.defaultProps = __assign(__assign({}, Flex.defaultProps), { alignItems: 'center', as: 'a', color: 'sys.neutral.border.active', fontSize: 'fs_16', justifyContent: 'space-between', pl: 'sp_16', py: 'sp_4', textAlign: 'left', textDecoration: 'none', width: 1 });
export default BaseNavigationLink;
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { dispatchTrackingEventGA4 } from '../../common/tracking/dispatchTrackingEventGA4';
import { Element } from '../../common/types/tracking';
var Event;
(function (Event) {
    Event["GlobalHeaderClick"] = "global-header-side-nav_click";
    Event["ShopInShopClick"] = "shop-in-shop-side-nav_click";
})(Event || (Event = {}));
var Interaction;
(function (Interaction) {
    Interaction["Open"] = "open";
    Interaction["ChangeLanguage"] = "change language";
})(Interaction || (Interaction = {}));
export var SideNavPlacement;
(function (SideNavPlacement) {
    SideNavPlacement["Primary"] = "primary";
    SideNavPlacement["Secondary"] = "secondary";
    SideNavPlacement["Courtesy"] = "courtesy";
})(SideNavPlacement || (SideNavPlacement = {}));
var home24ShopName = 'home24';
var getEventName = function (shopName) {
    return shopName === home24ShopName ? Event.GlobalHeaderClick : Event.ShopInShopClick;
};
export var trackNavigationClick = function (_a) {
    var element = _a.element, label = _a.label, position = _a.position, length = _a.length, _b = _a.placement, placement = _b === void 0 ? SideNavPlacement.Primary : _b, navLevel = _a.navLevel, _c = _a.shopName, shopName = _c === void 0 ? home24ShopName : _c, navItemParent = _a.navItemParent;
    dispatchTrackingEventGA4(__assign(__assign({ event: getEventName(shopName), element: element, clicked_text: label, placement: placement, list_position: position, list_length: length }, (typeof navLevel === 'number' && { nav_level: "L".concat(navLevel + 1) })), { shop_name: shopName, parent_category: navItemParent }));
};
var commonLanguageEventOptions = {
    event: Event.GlobalHeaderClick,
    element: Element.Dropdown,
    placement: SideNavPlacement.Courtesy,
    shop_name: home24ShopName,
};
export var trackLanguageClick = function () {
    dispatchTrackingEventGA4(__assign(__assign({}, commonLanguageEventOptions), { interaction: Interaction.Open }));
};
export var trackLanguageChange = function (label) {
    dispatchTrackingEventGA4(__assign(__assign({}, commonLanguageEventOptions), { interaction: Interaction.ChangeLanguage, label: label }));
};
export var trackLogoClick = function (shopName) {
    if (shopName === void 0) { shopName = home24ShopName; }
    dispatchTrackingEventGA4({
        event: getEventName(shopName),
        element: Element.Image,
        clicked_text: 'brand-logo',
        shop_name: shopName,
    });
};
export var trackCloseButtonClick = function (clicked_text, shopName) {
    if (shopName === void 0) { shopName = home24ShopName; }
    dispatchTrackingEventGA4({
        event: getEventName(shopName),
        element: Element.Button,
        clicked_text: clicked_text,
        shop_name: shopName,
    });
};
export var trackPromoImageClick = function (clicked_text) {
    dispatchTrackingEventGA4({
        event: Event.GlobalHeaderClick,
        element: Element.Image,
        clicked_text: clicked_text,
        shop_name: home24ShopName,
    });
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { borderRadius, height, size, space, themeGet, width } from 'styled-system';
import { Flex } from '../../common/components/Flex';
import Icon from '../../common/components/Icon';
import ResponsiveImage from '../../common/components/ResponsiveImage';
import { BodyBoldBB1, BodyRegularBR2 } from '../../common/components/Typography';
import { fill, textDecoration } from '../../common/theme/system-utilities';
import { trackPromoImageClick } from './tracking';
var ICON_SIZE = 20;
var PROMO_IMAGE_WIDTH = 284;
var PromotionImage = styled(ResponsiveImage)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), height, space, width);
var promotionImageStyles = {
    width: '100%',
    height: 'auto',
    mb: 'sp_8',
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    outline: 1px solid ", ";\n  }\n\n  &:active {\n    outline: 1px solid ", ";\n  }\n\n  &:hover div,\n  &:hover svg {\n    color: ", ";\n    fill: ", ";\n  }\n\n  &:active div,\n  &:active svg {\n    color: ", ";\n    fill: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    outline: 1px solid ", ";\n  }\n\n  &:active {\n    outline: 1px solid ", ";\n  }\n\n  &:hover div,\n  &:hover svg {\n    color: ", ";\n    fill: ", ";\n  }\n\n  &:active div,\n  &:active svg {\n    color: ", ";\n    fill: ", ";\n  }\n"])), borderRadius, space, textDecoration, themeGet('colors.sys.neutral.border.hover'), themeGet('colors.sys.neutral.border.inactive'), themeGet('colors.sys.neutral.text.hover'), themeGet('colors.sys.neutral.text.hover'), themeGet('colors.sys.neutral.text.inactive'), themeGet('colors.sys.neutral.text.inactive'));
var containerStyles = {
    as: 'a',
    borderRadius: 'medium',
    p: 'sp_8',
    textDecoration: 'none',
};
var ArrowIcon = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), fill, size);
var arrowIconStyles = {
    name: 'arrowRight',
    fill: 'sys.neutral.icon.default',
    size: ICON_SIZE,
};
var PromoImage = function (_a) {
    var ctaText = _a.ctaText, headlineText = _a.headlineText, ctaLink = _a.ctaLink, children = _a.children, imgData = __rest(_a, ["ctaText", "headlineText", "ctaLink", "children"]);
    return (React.createElement(Container, __assign({}, ((ctaLink === null || ctaLink === void 0 ? void 0 : ctaLink.fullUrl) && {
        href: ctaLink === null || ctaLink === void 0 ? void 0 : ctaLink.fullUrl,
        onClick: function () { return trackPromoImageClick(headlineText); },
    }), containerStyles),
        React.createElement(PromotionImage, __assign({}, imgData, { isLazy: true, widths: PROMO_IMAGE_WIDTH, alt: headlineText, src: imgData.x1 }, promotionImageStyles)),
        React.createElement(BodyBoldBB1, { pb: "sp_6" }, headlineText),
        ctaText && (React.createElement(Flex, null,
            React.createElement(BodyRegularBR2, null, ctaText),
            React.createElement(ArrowIcon, __assign({}, arrowIconStyles))))));
};
export default PromoImage;
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { space, width } from 'styled-system';
var ICON_SIZE = 18;
var EntryPointIcon = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", ";\n"], ["\n  ", "\n  ", ";\n"])), width, space);
EntryPointIcon.defaultProps = {
    mr: 'sp_16',
    width: ICON_SIZE,
};
export default EntryPointIcon;
var templateObject_1;

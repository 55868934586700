var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { alignItems, backgroundColor, boxShadow, padding, position, right, size, space, themeGet, top, width, zIndex, } from 'styled-system';
import { ButtonBase } from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import { LinkRegularLR1 } from '../../common/components/Typography';
import { SIDE_NAV_HEADER } from '../../common/constants/z-index';
import { useLocalization } from '../../common/providers/LocaleProvider';
import { fill, themeHeight } from '../../common/theme/system-utilities';
import { useSideNavTracking } from './hooks/useSideNavTracking';
import { NavigationLine } from './NavigationListItem';
import SideNavHeaderTitle from './SideNavHeaderTitle';
import { getNavigationData } from './utils';
var ARROW_ICON_WIDTH = 8;
var CLOSE_BUTTON_SIZE = 32;
var CLOSE_BUTTON_RIGHT = [16, null, 48, 64];
var getParentNavigationData = function (currentNavigation, initialNavigation) {
    var pointer = __spreadArray([], __read(currentNavigation), false);
    pointer.pop();
    return getNavigationData(pointer, initialNavigation);
};
var AbsoluteButton = styled(ButtonBase)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), position, top);
var absoluteButtonStyles = __assign(__assign({}, ButtonBase.defaultProps), { display: 'block', p: 'sp_0', position: 'absolute', top: 0 });
var BackButton = styled(ButtonBase)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), alignItems);
var backButtonStyles = __assign(__assign({}, ButtonBase.defaultProps), { display: 'flex', alignItems: 'center', mx: ['sp_16', null, 'sp_24', 'sp_40'], my: ['sp_16', null, 'sp_32', 'sp_40'] });
var CloseButton = styled(ButtonBase)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), right, position, size);
var closeButtonStyles = __assign(__assign({}, absoluteButtonStyles), { p: 'sp_8', position: 'absolute', right: CLOSE_BUTTON_RIGHT, size: CLOSE_BUTTON_SIZE });
var BackArrow = styled(Icon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), width, fill, space, padding);
var backArrowStyles = {
    name: 'arrowSmall',
    dataTestId: 'back-arrow',
    fill: 'sys.neutral.icon.default',
    pl: 'sp_0',
    pr: 'sp_16',
    width: ARROW_ICON_WIDTH,
};
var BackText = styled(LinkRegularLR1)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  &:hover {\n    color: ", ";\n    text-underline-offset: ", "px;\n    text-decoration: underline;\n    text-decoration-thickness: ", "px;\n    text-decoration-color: ", ";\n  }\n"], ["\n  &:hover {\n    color: ", ";\n    text-underline-offset: ", "px;\n    text-decoration: underline;\n    text-decoration-thickness: ", "px;\n    text-decoration-color: ", ";\n  }\n"])), themeGet('colors.sys.neutral.text.strong'), themeGet('space.sp_4'), themeGet('space.sp_2'), themeGet('colors.sys.neutral.text.strong'));
var CloseIcon = styled(Icon)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), size, fill);
var closeIconStyles = {
    name: 'closeSmall',
    dataTestId: 'close',
    fill: 'sys.neutral.icon.default',
    p: 'sp_16',
};
var HeaderContainer = styled(NavigationLine)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), backgroundColor, position, top, boxShadow, position, zIndex, themeHeight);
var headerContainerStyles = __assign(__assign({}, NavigationLine.defaultProps), { bg: 'sys.neutral.background.default', position: 'sticky', top: 0, justifyContent: 'flex-start', boxShadow: 'none', zIndex: SIDE_NAV_HEADER, mt: [null, null, 'sp_20', 'sp_24'], themeHeight: 'sp_64' });
var useSideNavHeader = function (_a) {
    var selectedItem = _a.selectedItem, initialNavigation = _a.initialNavigation, currentNavigation = _a.currentNavigation, isCascading = _a.isCascading, setCurrentNavigation = _a.setCurrentNavigation, setSelectedItem = _a.setSelectedItem;
    var hasSelectedItem = selectedItem !== null;
    var navigation = getNavigationData(hasSelectedItem ? __spreadArray([selectedItem], __read(currentNavigation), false) : currentNavigation, initialNavigation);
    var isRootLevel = !hasSelectedItem && (isCascading || currentNavigation.length === 0);
    var navigationParent = getParentNavigationData(currentNavigation, initialNavigation);
    var onBackButtonClick = useCallback(function () {
        var pointer = __spreadArray([], __read(currentNavigation), false);
        pointer.pop();
        setCurrentNavigation(pointer);
        setSelectedItem(null);
    }, [currentNavigation]);
    return { navigation: navigation, isRootLevel: isRootLevel, navigationParent: navigationParent, onBackButtonClick: onBackButtonClick };
};
var BackButtonSection = function (_a) {
    var isRootLevel = _a.isRootLevel, backButtonProps = _a.backButtonProps;
    var t = useLocalization().t;
    if (isRootLevel) {
        return null;
    }
    return (React.createElement(BackButton, __assign({}, backButtonProps, backButtonStyles),
        React.createElement(BackArrow, __assign({}, backArrowStyles)),
        React.createElement(BackText, null, t('h24_all_categories'))));
};
var SideNavHeader = function (_a) {
    var onClickCloseButton = _a.onClickCloseButton, logoUrl = _a.logoUrl, shopName = _a.shopName, shopUrl = _a.shopUrl, props = __rest(_a, ["onClickCloseButton", "logoUrl", "shopName", "shopUrl"]);
    var _b = useSideNavHeader(props), navigation = _b.navigation, isRootLevel = _b.isRootLevel, navigationParent = _b.navigationParent, onBackButtonClick = _b.onBackButtonClick;
    var _c = useSideNavTracking({
        navigationParentName: navigationParent.name,
        onBackButtonClick: onBackButtonClick,
        onClickCloseButton: onClickCloseButton,
        shopName: shopName,
    }), backButtonProps = _c.backButtonProps, closeButtonProps = _c.closeButtonProps;
    return (React.createElement(HeaderContainer, __assign({ as: "header" }, headerContainerStyles, (isRootLevel && { px: [null, null, 'sp_32', 'sp_48'] })),
        React.createElement(BackButtonSection, { isRootLevel: isRootLevel, backButtonProps: backButtonProps }),
        React.createElement(SideNavHeaderTitle, { navigationText: navigation.name, isRootLevel: isRootLevel, logoUrl: logoUrl, shopName: shopName, shopUrl: shopUrl }),
        React.createElement(CloseButton, __assign({}, closeButtonProps, closeButtonStyles),
            React.createElement(CloseIcon, __assign({}, closeIconStyles)))));
};
export default SideNavHeader;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

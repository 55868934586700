import { useEffect } from 'react';
export var SCROLL_ID_PREFIX = 'scroll-container';
export var useScrollHandling = function (currentNavigation, isOpen // isOpen is required to identify open/close event for mobile
) {
    useEffect(function () {
        var _a, _b;
        if (isOpen) {
            var scrollContainer = document.getElementById("".concat(SCROLL_ID_PREFIX, "-").concat(currentNavigation.length));
            var scrollContainerMobile = document.getElementById(SCROLL_ID_PREFIX);
            (_a = scrollContainer === null || scrollContainer === void 0 ? void 0 : scrollContainer.scroll) === null || _a === void 0 ? void 0 : _a.call(scrollContainer, { top: 0 });
            (_b = scrollContainerMobile === null || scrollContainerMobile === void 0 ? void 0 : scrollContainerMobile.scroll) === null || _b === void 0 ? void 0 : _b.call(scrollContainerMobile, { top: 0 });
        }
    }, [currentNavigation, isOpen]);
};

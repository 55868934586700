var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { backgroundColor, space } from 'styled-system';
import { flexGrow, listStyle } from '../../common/theme/system-utilities';
import BottomNavigationLink from './BottomNavigationLink';
import { useNavigationItems } from './hooks/useNavigationItems';
import { NavigationGroup, navigationGroupStyles } from './NavigationList';
import { NavigationItem } from './NavigationListItem';
var StyledNavigationGroup = styled(NavigationGroup)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), listStyle, space, backgroundColor, flexGrow);
StyledNavigationGroup.defaultProps = __assign(__assign({}, navigationGroupStyles), { backgroundColor: 'sys.neutral.background.strong', py: 'sp_16', px: [null, null, 'sp_32', 'sp_48'], flexGrow: 1, mb: 'sp_0' });
var BottomNavigationSection = function (props) {
    var navigation = useNavigationItems(props);
    return (React.createElement(StyledNavigationGroup, null, navigation.map(function (item, index) { return (React.createElement(NavigationItem, { key: index },
        React.createElement(BottomNavigationLink, __assign({}, item, { itemIndex: index, itemsCount: navigation.length })))); })));
};
export default React.memo(BottomNavigationSection);
var templateObject_1;

import { NAV_BUTTON_TEST_ID, NAV_LINK_TEST_ID } from './useNavigationItemProps';
var focusElement = function (container) { var _a; return ((_a = container.querySelector('a')) !== null && _a !== void 0 ? _a : container.querySelector('button')).focus(); };
var getMenus = function (container) {
    return container.querySelectorAll('[role="menu"]');
};
var focusFirstMenuLink = function (container) {
    return focusElement(getMenus(container)[0]);
};
var focusNewMenu = function (container) {
    var menus = getMenus(container);
    var lastMenu = menus[menus.length - 1];
    focusElement(lastMenu);
};
var focusPreviousMenu = function (container) {
    var menus = getMenus(container);
    var previousMenu = menus[menus.length - 2];
    focusElement(previousMenu);
};
var focusLastLink = function (container) {
    var menus = getMenus(container);
    var lastMenu = menus[menus.length - 1];
    var lastItem = lastMenu.querySelector('[role="menuitem"]:last-of-type');
    focusElement(lastItem);
};
var isMenuItem = function (element) {
    return element.getAttribute('data-testid') === NAV_BUTTON_TEST_ID ||
        element.getAttribute('data-testid') === NAV_LINK_TEST_ID;
};
var isCourtesyItem = function (element) {
    return !isMenuItem(element) && !element.closest('header');
};
var getClosestMenuItem = function () {
    return document.activeElement.closest('[role="menuitem"]');
};
var navigateDown = function (target, container) {
    var _a;
    var nextItem = (_a = getClosestMenuItem()) === null || _a === void 0 ? void 0 : _a.nextElementSibling;
    if (nextItem) {
        return focusElement(nextItem);
    }
    if (isMenuItem(target)) {
        // Navigate to the Courtesy section from the first menu
        return focusNewMenu(container);
    }
    focusFirstMenuLink(container);
};
var navigateUp = function (target, container, currentNavigation) {
    var _a;
    var previousItem = (_a = getClosestMenuItem()) === null || _a === void 0 ? void 0 : _a.previousElementSibling;
    if (previousItem) {
        return focusElement(previousItem);
    }
    if (currentNavigation.length === 0 && isCourtesyItem(target)) {
        // Navigate to the first menu from Courtesy section
        var lastItem = getMenus(container)[0].querySelector('[role="menuitem"]:last-of-type');
        return focusElement(lastItem);
    }
    focusLastLink(container);
};
var navigateRight = function (event, container) {
    event.preventDefault();
    event.target.click();
    // Timeout is added because of transition (drawer width) - scroll after focus causes jump.
    // We can't use "preventScroll", because of Safari compatibility (requires >=15 version)
    setTimeout(function () {
        focusNewMenu(container);
    }, 100);
};
var navigateLeft = function (currentNavigation, setCurrentNavigation, container) {
    setCurrentNavigation(currentNavigation.slice(0, -1));
    if (currentNavigation.length > 1) {
        return focusPreviousMenu(container);
    }
    focusFirstMenuLink(container);
};
export { navigateDown, navigateUp, navigateRight, navigateLeft };

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { borderColor, borderStyle } from 'styled-system';
import Separator from '../../common/components/Separator';
import { borderTopWidth } from '../../common/theme/system-utilities';
import { NavigationItem, NavigationItemBody } from './NavigationListItem';
import PromoImage from './PromoImage';
var StyledSeparator = styled(Separator)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), borderStyle, borderColor, borderTopWidth);
var styledSeparatorStyles = {
    my: 'sp_16',
    mx: 'sp_8',
    borderTopWidth: 0,
    borderStyle: 'solid',
    borderColor: 'sys.neutral.border.weak',
};
var getDividerIndex = function (items) {
    // No primary means all of them are secondary
    if (!items.find(function (item) { return item.isPrimary; })) {
        return -1;
    }
    var firstNonPrimary = items.findIndex(function (item) { return !item.isPrimary; });
    // Because of "All Category" item, ignore if index is 1
    if (firstNonPrimary === 1) {
        return -1;
    }
    return firstNonPrimary;
};
export var NavigationListItems = function (_a) {
    var items = _a.items, withPromoImage = _a.withPromoImage, props = __rest(_a, ["items", "withPromoImage"]);
    var dividerIndex = getDividerIndex(items);
    return (React.createElement(React.Fragment, null, items.map(function (_a, id) {
        var promoImgV2 = _a.promoImgV2, item = __rest(_a, ["promoImgV2"]);
        var dataTestId = "navigation-item-".concat(id);
        if (promoImgV2) {
            return withPromoImage ? (React.createElement(NavigationItem, { key: id, "data-testid": dataTestId, flexDirection: "column" },
                React.createElement(StyledSeparator, __assign({}, styledSeparatorStyles)),
                React.createElement(PromoImage, __assign({}, promoImgV2)))) : null;
        }
        var withDivider = dividerIndex === id;
        return (React.createElement(NavigationItem, __assign({ key: id, "data-testid": dataTestId }, (withDivider && { flexDirection: 'column' })),
            withDivider && React.createElement(StyledSeparator, __assign({}, styledSeparatorStyles)),
            React.createElement(NavigationItemBody, __assign({}, props, { id: id, item: item, listLength: items.length }))));
    })));
};
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { space } from 'styled-system';
import { useLocalization } from '../../common/providers/LocaleProvider';
import BottomNavigationSection from './BottomNavigationSection';
import LanguageSwitch from './LanguageSwitch';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
var bottomSectionContainerStyles = {
    marginTop: 'auto',
    ml: [null, null, -32, -48],
    mr: [null, null, -32, -48],
};
// Applying this when second column in expanded, so first column gets more narrow
var MARGIN_RIGHT_SMALL = [null, null, -20, -24];
var BottomSection = function (_a) {
    var customer = _a.customer, uspEntryPoints = _a.uspEntryPoints, isExpanded = _a.isExpanded;
    var _b = useLocalization(), t = _b.t, getLanguages = _b.getLanguages;
    return (React.createElement(Container, __assign({}, bottomSectionContainerStyles, (isExpanded && { mr: MARGIN_RIGHT_SMALL })),
        React.createElement(BottomNavigationSection, { customer: customer, uspEntryPoints: uspEntryPoints }),
        React.createElement(LanguageSwitch, { languages: getLanguages().map(function (item) { return (__assign(__assign({}, item), { language: t("h24_language_".concat(item.language)) })); }) })));
};
export default BottomSection;
var templateObject_1;

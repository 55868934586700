var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { backgroundColor, borderBottom, borderRadius, boxShadow, color, space, themeGet, } from 'styled-system';
import { boxSizing } from '../../common/theme/system-utilities';
import { Element } from '../../common/types/tracking';
import BaseNavigationLink from './BaseNavigationLink';
import { SideNavPlacement, trackNavigationClick } from './tracking';
var Link = styled(BaseNavigationLink)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:not(:last-of-type) {\n    ", ";\n  }\n\n  ", "\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:not(:last-of-type) {\n    ", ";\n  }\n\n  ", "\n"])), backgroundColor, borderRadius, boxShadow, boxSizing, color, space, borderBottom, function (props) { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    &:hover,\n    &:active {\n      color: ", ";\n    }\n  "], ["\n    &:hover,\n    &:active {\n      color: ", ";\n    }\n  "])), themeGet('colors.sys.neutral.text.active')(props)); });
Link.defaultProps = __assign(__assign({}, BaseNavigationLink.defaultProps), { backgroundColor: 'sys.neutral.background.strong', borderBottom: 'none', borderRadius: 'medium', boxShadow: 'none', boxSizing: 'content-box', color: 'sys.neutral.text.inverted', fontWeight: 'normal', justifyContent: 'flex-start', py: 'sp_8', tabIndex: 0 });
var BottomNavigationLink = function (_a) {
    var label = _a.label, icon = _a.icon, itemIndex = _a.itemIndex, itemsCount = _a.itemsCount, props = __rest(_a, ["label", "icon", "itemIndex", "itemsCount"]);
    var handleClick = function () {
        return trackNavigationClick({
            element: Element.Link,
            label: label,
            placement: SideNavPlacement.Courtesy,
            position: itemIndex,
            length: itemsCount,
        });
    };
    return (React.createElement(Link, __assign({}, props, { onClick: handleClick }),
        icon,
        React.createElement("span", { dangerouslySetInnerHTML: { __html: label } })));
};
export default BottomNavigationLink;
var templateObject_1, templateObject_2;

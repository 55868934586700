var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { backgroundPosition, backgroundSize, border, borderColor, borderRadius, borderStyle, borderWidth, color, fontSize, height, maxWidth, space, themeGet, width, } from 'styled-system';
import { breakpoint } from '../../common/theme/helper';
export var arrowIconURI = {
    normal: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy43MDcxIDkuMjkyODlDMTguMDY3NiA5LjY1MzM4IDE4LjA5NTMgMTAuMjIwNiAxNy43OTAzIDEwLjYxMjlMMTcuNzA3MSAxMC43MDcxTDEyLjcwNzEgMTUuNzA3MUMxMi4zNDY2IDE2LjA2NzYgMTEuNzc5NCAxNi4wOTUzIDExLjM4NzEgMTUuNzkwM0wxMS4yOTI5IDE1LjcwNzFMNi4yOTI4OSAxMC43MDcxQzUuOTAyMzcgMTAuMzE2NiA1LjkwMjM3IDkuNjgzNDIgNi4yOTI4OSA5LjI5Mjg5QzYuNjUzMzggOC45MzI0MSA3LjIyMDYxIDguOTA0NjggNy42MTI5IDkuMjA5N0w3LjcwNzExIDkuMjkyODlMMTIgMTMuNTg1TDE2LjI5MjkgOS4yOTI4OUMxNi42NTM0IDguOTMyNDEgMTcuMjIwNiA4LjkwNDY4IDE3LjYxMjkgOS4yMDk3TDE3LjcwNzEgOS4yOTI4OVoiIGZpbGw9IiM3MTc2NzgiLz4KPC9zdmc+Cg==',
    hover: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiPgogICAgPGRlZnM+CiAgICAgICAgPHBhdGggaWQ9ImEiIGQ9Ik0xLjA1MyAxOUwwIDE4LjExM2w4LjA3LTguNTI0TDAgMS4wNjUgMS4wNTMgMCAxMCA5LjU5eiIgdHJhbnNmb3JtPSJyb3RhdGUoOTApLCB0cmFuc2xhdGUoNCwgLTE0KSIgLz4KICAgIDwvZGVmcz4KICAgIDx1c2UgZmlsbD0iI0Y0NTMzNCIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMSA3KSIgeGxpbms6aHJlZj0iI2EiLz4KPC9zdmc+Cg==',
};
export var BaseSelect = styled.select(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n\n  margin: 0;\n  appearance: none;\n  background-color: ", ";\n  background-image: url('", "');\n  background-repeat: no-repeat, repeat;\n  cursor: pointer;\n\n  &:disabled {\n    color: ", ";\n    cursor: not-allowed;\n    background-color: ", ";\n    border-color: ", ";\n  }\n\n  &:focus-visible {\n    outline: 1px solid ", ";\n    border-color: ", ";\n  }\n\n  &:hover {\n    outline: 1px solid ", ";\n    border-color: ", ";\n  }\n\n  &:active {\n    outline: 1px solid ", ";\n    border-color: ", ";\n  }\n"], ["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n\n  margin: 0;\n  appearance: none;\n  background-color: ", ";\n  background-image: url('", "');\n  background-repeat: no-repeat, repeat;\n  cursor: pointer;\n\n  &:disabled {\n    color: ", ";\n    cursor: not-allowed;\n    background-color: ", ";\n    border-color: ", ";\n  }\n\n  &:focus-visible {\n    outline: 1px solid ", ";\n    border-color: ", ";\n  }\n\n  &:hover {\n    outline: 1px solid ", ";\n    border-color: ", ";\n  }\n\n  &:active {\n    outline: 1px solid ", ";\n    border-color: ", ";\n  }\n"])), space, color, borderStyle, borderWidth, borderColor, border, fontSize, backgroundSize, backgroundPosition, themeGet('colors.sys.neutral.background.default'), arrowIconURI.normal, themeGet('colors.sys.neutral.border.default'), themeGet('colors.sys.neutral.background.hover'), themeGet('colors.sys.neutral.background.inverted'), themeGet('colors.sys.secondary.border.focus'), themeGet('colors.sys.secondary.border.focus'), themeGet('colors.sys.neutral.border.hover'), themeGet('colors.sys.neutral.border.hover'), themeGet('colors.sys.neutral.border.active'), themeGet('colors.sys.neutral.border.active'));
var StyledOutlineSelect = styled(BaseSelect)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n  ", "\n  ", "\n"])), width, height, maxWidth, borderRadius);
export var outlineSelectProps = {
    /*
      safari/IOS needs minimum 16px font size to avoid automatic zoom-in effect.
      We don't want to handle via meta tag which is inappropriate among devices.
      Hence increased font size  irrespective of mock-up.
    */
    fontSize: 'fs_16',
    pl: 'sp_8',
    color: 'sys.neutral.text.strong',
    height: '40px',
    borderRadius: 'small',
    backgroundSize: '24px auto, 100%',
    backgroundPosition: 'right 7px top 50%, 0 0',
    borderStyle: 'solid',
    borderWidth: 'small',
    borderColor: 'sys.neutral.border.inactive',
};
export var OutlineSelect = function (_a) {
    var title = _a.title, name = _a.name, value = _a.value, onSelect = _a.onSelect, _b = _a.options, options = _b === void 0 ? [] : _b, _c = _a.width, selectWidth = _c === void 0 ? '82px' : _c, props = __rest(_a, ["title", "name", "value", "onSelect", "options", "width"]);
    return (React.createElement(StyledOutlineSelect, __assign({ "data-testid": "outline-select", title: title, name: name, value: value, onChange: onSelect, width: selectWidth }, outlineSelectProps, props), options.map(function (val, index) { return (React.createElement("option", { value: val, key: index }, val)); })));
};
var StyledBorderlessSelectV2 = function (props) {
    return props.optionsV2 && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: inherit;\n    background-color: inherit;\n    padding-left: 96px;\n    padding-right: 30px;\n    height: 40px;\n\n    ", " {\n      padding-left: 102px;\n      height: 48px;\n    }\n\n    &:disabled {\n      color: ", ";\n      cursor: not-allowed;\n      background-color: ", ";\n      border-color: ", ";\n    }\n    &:focus-visible,\n    &:hover,\n    &:active {\n      outline: none;\n    }\n  "], ["\n    font-size: inherit;\n    background-color: inherit;\n    padding-left: 96px;\n    padding-right: 30px;\n    height: 40px;\n\n    ", " {\n      padding-left: 102px;\n      height: 48px;\n    }\n\n    &:disabled {\n      color: ", ";\n      cursor: not-allowed;\n      background-color: ", ";\n      border-color: ", ";\n    }\n    &:focus-visible,\n    &:hover,\n    &:active {\n      outline: none;\n    }\n  "])), breakpoint(2)(props), themeGet('colors.sys.neutral.border.default')(props), themeGet('colors.sys.neutral.background.hover')(props), themeGet('colors.sys.neutral.background.inverted')(props));
};
var StyledBorderlessSelect = styled(BaseSelect)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: bold;\n  > option {\n    font-weight: normal;\n    color: ", ";\n  }\n  ", "\n"], ["\n  font-weight: bold;\n  > option {\n    font-weight: normal;\n    color: ", ";\n  }\n  ", "\n"])), themeGet('colors.sys.neutral.border.strong'), StyledBorderlessSelectV2);
StyledBorderlessSelect.defaultProps = {
    pr: 'sp_24',
    border: 'none',
    color: 'sys.neutral.border.strong',
    fontSize: 'fs_18',
    backgroundSize: '24px auto, 100%',
    backgroundPosition: 'right 10px top 50%, 0 0',
};
export var BorderlessSelect = function (_a) {
    var title = _a.title, name = _a.name, value = _a.value, _b = _a.options, options = _b === void 0 ? [] : _b, onSelect = _a.onSelect, onClick = _a.onClick, className = _a.className, ariaLabel = _a.ariaLabel, optionsV2 = _a.optionsV2;
    return (React.createElement(StyledBorderlessSelect, __assign({ "data-testid": "borderless-select", title: title, name: name, value: value, onChange: onSelect, tabIndex: optionsV2 ? -1 : 0, className: className, "aria-label": ariaLabel, optionsV2: optionsV2 }, (onClick && { onClick: onClick })), options.map(function (_a, index) {
        var option = _a.option, localized = _a.localized;
        return (React.createElement("option", { value: option, key: index }, localized));
    })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

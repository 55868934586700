var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { borderColor, borderStyle, borderWidth, height, maxWidth, minWidth } from 'styled-system';
import Drawer from '../../common/components/Drawer';
import { Flex } from '../../common/components/Flex';
import { EventBusEvent } from '../../common/enums/EventBus';
import { useCloseDrawer } from '../../common/hooks/drawer';
import { borderLeftWidth, overflowX, overflowY } from '../../common/theme/system-utilities';
import { SideNavType } from '../../common/utils/navigation';
import BottomSection from './BottomSection';
import { useNavigation } from './hooks/useNavigation';
import { SCROLL_ID_PREFIX, useScrollHandling } from './hooks/useScrollHandling';
import { useSideNavKeyboardNavigation } from './hooks/useSideNavKeyboardNavigation';
import NavigationList, { NAV_TITLE_ID } from './NavigationList';
import SideNavHeader from './SideNavHeader';
import { geSideNavDrawerWrapperStyles, getSideDrawerWidth, getSideNavDrawerContainerStyles, } from './utils';
var SideNavContent = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), overflowX, height);
var sideNavContentStyles = {
    overflowX: 'hidden',
    height: '100%',
};
var DrawerContainer = styled(Flex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), height, overflowY, overflowX, minWidth, maxWidth);
var drawerContainerStyles = __assign(__assign({}, Flex.defaultProps), { flexDirection: 'column', height: '100%', overflowY: 'auto', pl: [null, null, 'sp_20', 'sp_24'], pr: [null, null, 'sp_20', 'sp_24'], overflowX: 'hidden', minWidth: [null, null, 280, 320], maxWidth: [null, null, 280, 320] });
var VerticalDivider = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), borderWidth, borderLeftWidth, borderStyle, borderColor);
var verticalDividerStyles = {
    borderWidth: 1,
    borderLeftWidth: 0,
    borderStyle: 'solid',
    borderColor: 'sys.neutral.border.weak',
};
// eslint-disable-next-line max-lines-per-function
var SideNav = function (_a) {
    var unsortedNavigation = _a.navigation, logoUrl = _a.logoUrl, shopName = _a.shopName, shopUrl = _a.shopUrl, _b = _a.withBottomSection, withBottomSection = _b === void 0 ? true : _b, customer = _a.customer, _c = _a.uspEntryPoints, uspEntryPoints = _c === void 0 ? [] : _c, _d = _a.type, type = _d === void 0 ? SideNavType.Global : _d;
    var _e = useNavigation(unsortedNavigation, type), isOpen = _e.isOpen, currentNavigation = _e.currentNavigation, closeHandler = _e.closeHandler, setCurrentNavigation = _e.setCurrentNavigation, selectedItem = _e.selectedItem, setSelectedItem = _e.setSelectedItem, navigation = _e.navigation, clickHandler = _e.clickHandler, isCascading = _e.isCascading, initialNavigation = _e.initialNavigation, navItemParent = _e.navItemParent;
    useCloseDrawer({
        toggleEvent: EventBusEvent.ToggleSideNav,
        callback: closeHandler,
        isOpen: isOpen,
    });
    var _f = useSideNavKeyboardNavigation({
        isOpen: isOpen,
        isCascading: isCascading,
        currentNavigation: currentNavigation,
        setCurrentNavigation: setCurrentNavigation,
    }), drawerRef = _f.drawerRef, keyDownHandler = _f.keyDownHandler;
    useScrollHandling(currentNavigation, isOpen);
    return (React.createElement(Drawer, { isOpen: isOpen, isSideNav: true, width: getSideDrawerWidth(isOpen, isCascading, currentNavigation.length), onBackdropClick: closeHandler, ariaLabelledby: NAV_TITLE_ID, containerRef: drawerRef, onKeyDown: keyDownHandler }, isOpen ? (React.createElement(React.Fragment, null,
        React.createElement(SideNavHeader, { initialNavigation: initialNavigation, onClickCloseButton: closeHandler, currentNavigation: currentNavigation, setCurrentNavigation: setCurrentNavigation, selectedItem: selectedItem, setSelectedItem: setSelectedItem, isCascading: isCascading, logoUrl: logoUrl, shopName: shopName, shopUrl: shopUrl }),
        Array.isArray(navigation) ? (
        // Desktop (multiple columns, expanding)
        React.createElement(SideNavContent, __assign({}, sideNavContentStyles), navigation.map(function (nav, level) {
            return (React.createElement(Flex, __assign({ key: level }, geSideNavDrawerWrapperStyles(level, selectedItem)),
                React.createElement(DrawerContainer, __assign({ flex: "1", id: "".concat(SCROLL_ID_PREFIX, "-").concat(level) }, drawerContainerStyles, getSideNavDrawerContainerStyles(level, navigation.length)),
                    React.createElement(NavigationList, { navigation: nav, onNavigationClick: clickHandler(level), activeItem: currentNavigation[level], navLevel: level, selectedItem: selectedItem, shopName: shopName, navItemParent: navItemParent }),
                    withBottomSection && level === 0 && selectedItem === null && (React.createElement(BottomSection, { customer: customer, uspEntryPoints: uspEntryPoints, isExpanded: navigation.length > 1 }))),
                level < navigation.length - 1 && (React.createElement(VerticalDivider, __assign({}, verticalDividerStyles)))));
        }))) : (
        // Mobile (single column, being replaced while navigating)
        React.createElement(DrawerContainer, __assign({ id: SCROLL_ID_PREFIX }, drawerContainerStyles),
            React.createElement(NavigationList, { navigation: navigation, onNavigationClick: clickHandler(currentNavigation.length), navLevel: currentNavigation.length, selectedItem: selectedItem, shopName: shopName, navItemParent: navItemParent }),
            withBottomSection && (React.createElement(BottomSection, { customer: customer, uspEntryPoints: uspEntryPoints })))))) : null));
};
export default SideNav;
var templateObject_1, templateObject_2, templateObject_3;

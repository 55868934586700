var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { backgroundColor, border, borderRadius, boxShadow, color, display, fontWeight, height, size, space, themeGet, width, } from 'styled-system';
import { Flex } from '../../common/components/Flex';
import Icon, { Rotate } from '../../common/components/Icon';
import { modifiers } from '../../common/theme/helper';
import { boxSizing, cursor, fill, objectFit, themeMinHeight, } from '../../common/theme/system-utilities';
import BaseNavigationLink from './BaseNavigationLink';
import { useNavigationItemProps } from './hooks/useNavigationItemProps';
var RIGHT_ARROW_SIZE = 16;
export var NavigationLine = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  box-shadow: inset 0 -1px 0 ", ";\n"], ["\n  ", ";\n  box-shadow: inset 0 -1px 0 ", ";\n"])), themeMinHeight, themeGet('colors.sys.neutral.border.weak'));
NavigationLine.defaultProps = __assign(__assign({}, Flex.defaultProps), { alignItems: 'center', themeMinHeight: 'sp_48' });
export var NavigationItem = styled(NavigationLine)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &:last-of-type {\n    box-shadow: none;\n  }\n  ", ";\n"], ["\n  &:last-of-type {\n    box-shadow: none;\n  }\n  ", ";\n"])), boxShadow);
NavigationItem.defaultProps = __assign(__assign({}, NavigationLine.defaultProps), { alignItems: 'stretch', as: 'li', boxShadow: 'none', mx: 'sp_8', my: 'sp_2', role: 'menuitem' });
var Button = styled(BaseNavigationLink)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    color: ", ";\n    background-color: ", ";\n    border: 1px solid ", ";\n    [role='presentation'] {\n      fill: ", ";\n    }\n  }\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    color: ", ";\n    background-color: ", ";\n    border: 1px solid ", ";\n    [role='presentation'] {\n      fill: ", ";\n    }\n  }\n  ", ";\n"])), backgroundColor, borderRadius, border, color, cursor, modifiers, themeMinHeight, themeGet('colors.sys.neutral.text.active'), themeGet('colors.sys.neutral.background.hover'), themeGet('colors.sys.neutral.border.hover'), themeGet('colors.sys.neutral.icon.hover'), function (props) {
    return props.isActive && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      color: ", ";\n      background-color: ", ";\n      border: 1px solid ", ";\n      [role='presentation'] {\n        fill: ", ";\n      }\n    }\n  "], ["\n      color: ", ";\n      background-color: ", ";\n      border: 1px solid ", ";\n      [role='presentation'] {\n        fill: ", ";\n      }\n    }\n  "])), themeGet('colors.sys.neutral.text.active')(props), themeGet('colors.sys.neutral.background.active')(props), themeGet('colors.sys.neutral.border.active')(props), themeGet('colors.sys.neutral.icon.active')(props));
});
Button.defaultProps = __assign(__assign({}, BaseNavigationLink.defaultProps), { bg: 'transparent', border: '1px solid transparent', borderRadius: 'medium', color: 'sys.neutral.text.default', cursor: 'pointer', m: 'sp_0', px: 'sp_8', themeMinHeight: 'sp_48' });
var Link = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), boxSizing);
Link.defaultProps = __assign(__assign({}, Button.defaultProps), { boxSizing: 'border-box', px: 'sp_8', tabIndex: 0, themeMinHeight: 'sp_48' });
var TopCategoryLink = styled(BaseNavigationLink)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  ", ";\n\n  text-underline-offset: ", "px;\n\n  &:hover {\n    background-color: ", ";\n    font-weight: 700;\n    text-decoration: solid underline ", "\n      ", "px;\n  }\n\n  &:active {\n    color: ", ";\n    text-decoration: solid underline ", "\n      ", "px;\n  }\n"], ["\n  ", ";\n  ", ";\n\n  text-underline-offset: ", "px;\n\n  &:hover {\n    background-color: ", ";\n    font-weight: 700;\n    text-decoration: solid underline ", "\n      ", "px;\n  }\n\n  &:active {\n    color: ", ";\n    text-decoration: solid underline ", "\n      ", "px;\n  }\n"])), cursor, fontWeight, themeGet('space.sp_4'), themeGet('colors.sys.neutral.background.default'), themeGet('colors.sys.neutral.text.default'), themeGet('space.sp_2'), themeGet('colors.sys.neutral.text.strong'), themeGet('colors.sys.neutral.text.strong'), themeGet('space.sp_2'));
TopCategoryLink.defaultProps = __assign(__assign({}, BaseNavigationLink.defaultProps), { textDecoration: 'underline', tabIndex: 0, pl: 'sp_8', fontSize: 'fs_16', cursor: 'pointer', color: 'sys.neutral.text.default' });
var RightArrow = styled(Icon)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), fill, height, size, space, width);
var rightArrowStyles = {
    name: 'arrowLargeBold',
    rotate: Rotate.Right,
    role: 'presentation',
    fill: 'sys.neutral.icon.default',
    pl: 'sp_8',
    size: RIGHT_ARROW_SIZE,
};
var Image = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, objectFit, size, space, borderRadius);
var imageStyles = {
    borderRadius: 'small',
    display: 'inline-block',
    mr: 'sp_8',
    objectFit: 'contain',
    size: 32,
};
// Assume that first item is always topCategory item
var isTopCategory = function (_a) {
    var id = _a.id, navLevel = _a.navLevel, selectedItem = _a.selectedItem;
    return id === 0 && (navLevel > 0 || selectedItem !== null);
};
var Thumbnail = function (_a) {
    var thumbnail = _a.thumbnail, name = _a.name, isPrimary = _a.isPrimary;
    return (React.createElement(Flex, { alignItems: "center" },
        (thumbnail === null || thumbnail === void 0 ? void 0 : thumbnail.url) && isPrimary && React.createElement(Image, __assign({ src: thumbnail.url, alt: name }, imageStyles)),
        React.createElement("span", null, name)));
};
export var NavigationItemBody = function (_a) {
    var _b;
    var id = _a.id, item = _a.item, navLevel = _a.navLevel, selectedItem = _a.selectedItem, props = __rest(_a, ["id", "item", "navLevel", "selectedItem"]);
    var isAllCategory = isTopCategory({
        id: id,
        navLevel: navLevel,
        selectedItem: selectedItem,
    });
    var _c = useNavigationItemProps(__assign({ id: id, item: item, navLevel: navLevel, selectedItem: selectedItem }, props)), navigationLinkProps = _c.navigationLinkProps, navigationButtonProps = _c.navigationButtonProps;
    if (isAllCategory) {
        return React.createElement(TopCategoryLink, __assign({}, navigationLinkProps), item.name);
    }
    return ((_b = item.children) === null || _b === void 0 ? void 0 : _b.length) ? (React.createElement(Button, __assign({}, navigationButtonProps),
        React.createElement(Thumbnail, __assign({}, item)),
        React.createElement(RightArrow, __assign({}, rightArrowStyles)))) : (React.createElement(Link, __assign({}, navigationLinkProps),
        React.createElement(Thumbnail, __assign({}, item))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;

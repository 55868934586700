var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Element } from '../../../common/types/tracking';
import { SideNavPlacement, trackNavigationClick } from '../tracking';
export var NAV_BUTTON_TEST_ID = 'navigation-button';
export var NAV_LINK_TEST_ID = 'navigation-link';
export var useNavigationItemProps = function (_a) {
    var id = _a.id, item = _a.item, groupId = _a.groupId, listLength = _a.listLength, navLevel = _a.navLevel, onNavigationClick = _a.onNavigationClick, activeItem = _a.activeItem, selectedItem = _a.selectedItem, shopName = _a.shopName, navItemParent = _a.navItemParent;
    var trackingOptions = {
        label: item.name,
        position: id,
        length: listLength,
        placement: item.isPrimary || typeof item.isPrimary !== 'boolean'
            ? SideNavPlacement.Primary
            : SideNavPlacement.Secondary,
        navLevel: selectedItem !== null && typeof navLevel === 'number' ? navLevel + 1 : navLevel,
        shopName: shopName,
        navItemParent: navItemParent,
    };
    return {
        navigationButtonProps: {
            'data-item-id': id,
            'data-group-id': groupId,
            'data-testid': NAV_BUTTON_TEST_ID,
            onClick: function (event) {
                onNavigationClick(event);
                trackNavigationClick(__assign(__assign({}, trackingOptions), { element: Element.Button }));
            },
            isActive: activeItem === id,
            as: 'button',
            modifiers: item.modifiers,
        },
        navigationLinkProps: {
            'data-testid': NAV_LINK_TEST_ID,
            href: item.href,
            modifiers: item.modifiers,
            onClick: function () {
                trackNavigationClick(__assign(__assign({}, trackingOptions), { element: Element.Link }));
            },
        },
    };
};

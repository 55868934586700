var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useLocalization } from '../../../common/providers/LocaleProvider';
import { getNavigationItems, getUspEntryPoints } from '../utils';
export var useNavigationItems = function (_a) {
    var customer = _a.customer, uspEntryPoints = _a.uspEntryPoints;
    var l10n = useLocalization();
    var navigation = getNavigationItems({
        l10n: l10n,
        customer: customer,
    });
    navigation = __spreadArray(__spreadArray([], __read(navigation), false), __read(getUspEntryPoints(uspEntryPoints)), false);
    return navigation;
};
export var useShowPromoImage = function (_a) {
    var navLevel = _a.navLevel, selectedItem = _a.selectedItem;
    return (navLevel === 0 && selectedItem !== null) ||
        (navLevel === 2 && selectedItem === null);
};

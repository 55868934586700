import { useCallback, useRef } from 'react';
import { Key } from '../../../common/enums/Keyboard';
import { navigateDown, navigateLeft, navigateRight, navigateUp, } from './keyboard-nav-utils';
var getNavigationHandler = function (_a) {
    var container = _a.container, currentNavigation = _a.currentNavigation, setCurrentNavigation = _a.setCurrentNavigation;
    return function (event) {
        var target = event.target;
        if (event.code === Key.ArrowDown) {
            navigateDown(target, container);
        }
        if (event.code === Key.ArrowUp) {
            navigateUp(target, container, currentNavigation);
        }
        if ([Key.ArrowRight, Key.Space, Key.Enter].includes(event.code) &&
            target instanceof HTMLButtonElement &&
            target.closest('[role="menuitem"]')) {
            navigateRight(event, container);
        }
        if (event.code === Key.ArrowLeft && currentNavigation.length > 0) {
            navigateLeft(currentNavigation, setCurrentNavigation, container);
        }
    };
};
export var useSideNavKeyboardNavigation = function (_a) {
    var isOpen = _a.isOpen, isCascading = _a.isCascading, currentNavigation = _a.currentNavigation, setCurrentNavigation = _a.setCurrentNavigation;
    var drawerRef = useRef(null);
    var getHandler = useCallback(function () {
        if (!isCascading || !isOpen || !drawerRef.current) {
            return;
        }
        return getNavigationHandler({
            container: drawerRef.current,
            currentNavigation: currentNavigation,
            setCurrentNavigation: setCurrentNavigation,
        });
    }, [isOpen, isCascading, drawerRef, currentNavigation, setCurrentNavigation]);
    return { drawerRef: drawerRef, keyDownHandler: getHandler() };
};
